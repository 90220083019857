    @import url('https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
    @import url('https://fonts.googleapis.com/css?family=Engagement');
    @import url('https://fonts.googleapis.com/css?family=Great+Vibes');
    @import "modules/responsive-type.scss";
    $primary: #ed6449;
    /* MAIN COLOR */
    
    $secondary: #394e62;
    /* SECONDARY COLOR */
    
    $blk: #333;
    /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
    
    $wht: #fafafa;
    $border-radius: 0px;
    /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
    
    $footerLinks: #fafafa;
    // Set your maximum and minimum screen sizes.
    $min_width: 320px;
    $max_width: 1920px;
    // Font sizes will range between the $min_font and $max_font.
    $min_font: 15px;
    $max_font: 22px;
    // Change these values to change the "scale" between different headers
    // (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 
    $mod_1: 1.4; // mobile
    $mod_2: 1.6; // desktop
    html,
    body {
        height: 100%;
    }
    
    html {
        @include fluid-type($min_width,
        $max_width,
        $min_font,
        $max_font);
    }
    
    p,
    ol,
    ul {
        font-size: 1rem;
        font-family: 'Montserrat', sans-serif;
    }
    
    a {
        font-family: 'Engagement', cursive;
        font-size: 1.5rem;
    }
    
    h1 {
        font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem;
        @include fluid-type($min_width,
        $max_width,
        $mod_1*$mod_1*$mod_1 *$min_font,
        $mod_2*$mod_2*$mod_2 *$min_font);
        font-family: 'Engagement', cursive;
    }
    
    h2 {
        font-size: $mod_1*$mod_1*$mod_1 *1rem;
        @include fluid-type($min_width,
        $max_width,
        $mod_1*$mod_1*$mod_1 *$min_font,
        $mod_2*$mod_2*$mod_2 *$min_font);
        font-family: 'Engagement', cursive;
    }
    
    h3 {
        font-size: $mod_1*$mod_1 *1rem;
        @include fluid-type($min_width,
        $max_width,
        $mod_1*$mod_1 *$min_font,
        $mod_2*$mod_2 *$min_font);
        font-family: 'Engagement', cursive;
    }
    
    body {
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }
    
    .flash {
        display: none;
    }
    
    q,
    blockquote {
        quotes: "“" "”";
    }
    
    .navbarFixed {
        background: #fafafa;
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 999;
    }
    /* Change autocomplete styles in WebKit */
    
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus input:-webkit-autofill,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border: 1px solid #0065dd;
        -webkit-text-fill-color: #0065dd;
        -webkit-box-shadow: 0 0 0px 1000px #000 inset;
        transition: background-color 5000s ease-in-out 0s;
    }
    
    nav.navbar {
        z-index: 1000;
        border: none;
        border-radius: $border-radius;
        .navbar-nav {
            >li>a {
                text-align: center;
                margin-top: 25px;
                display: flex;
                align-items: center;
                color: $blk;
                @media (max-width: 767px) {
                    margin-top: 0;
                    padding: 6px 0px;
                    display: inline-block;
                }
                &:focus,
                &:active {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
                &:hover {
                    background: $primary;
                    border-radius: 12px;
                    color: $wht;
                    @media (max-width: 1024px) {
                        background: transparent;
                        color: $blk;
                        outline: 0;
                    }
                }
            }
        }
    }
    
    .navbar-right {
        margin-top: 0px;
    }
    
    .navbar-toggle {
        margin: 20px 15px 8px 0px;
        border: 1px solid transparent;
        border-radius: 4px;
        @media (max-width: 767px) and (orientation: landscape) {
            margin: 16px 15px 8px 0px;
        }
    }
    /** LOGIN FORM **/
    
    @mixin btn {
        border: none;
        border-radius: $border-radius;
        background: $primary;
        color: $wht;
        padding: 0.5em 2em;
        font-size: 1.25em;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 1px;
        display: inline-block;
        margin: 2em 0em;
        transition: 0.7s ease all;
        &:hover {
            background: lighten($primary, 10%);
            color: lighten($wht, 10%);
            text-decoration: none;
            @media (max-width: 1024px) {
                background: $secondary;
                color: $wht;
                box-shadow: 0px 0px 5px $blk;
            }
        }
    }
    
    .btn-default {
        @include btn;
    }
    
    .modal-dialog {
        max-width: 300px;
        text-align: center;
        margin: 6em auto;
        .close {
            display: none;
        }
        .modal-content {
            color: $primary;
            h2 {
                text-align: center;
            }
        }
        input {
            border: 1px solid #777;
            text-align: center;
        }
        input#username,
        input#password {
            @media (max-width: 1024px) {
                font-size: 16px;
            }
        }
        button {
            background: transparent;
            color: $wht;
            display: block;
            border: none;
            padding: 1em 2em;
            margin: 0 auto;
            font-size: 18px;
            &:hover {
                background: transparent;
                color: #fff;
                box-shadow: none;
                text-shadow: 0px 0px 3px $blk;
                border: none;
            }
        }
        input[type="submit"] {
            @include btn;
            display: block;
            width: 75%;
            font-size: 18px;
            margin: 0 auto;
        }
    }
    
    .modal-header,
    .modal-footer {
        background: $primary;
        color: $wht;
    }
    
    .modal-open {
        overflow: auto;
        padding-right: 0px !important;
    }
    
    input#username {
        margin-bottom: 20px;
    }
    /** END LOGIN FORM **/
    
    footer {
        padding: 50px 0px 20px;
        background: #000;
        color: $footerLinks;
        font-size: 15px;
        a {
            color: $footerLinks;
            white-space: nowrap;
            font-size: 15px;
            @media (max-width: 990px) {
                font-size: 13px;
            }
            &:hover {
                color: lighten($footerLinks, 10%);
                outline: 0 !important;
                text-decoration: none;
            }
            &:focus {
                color: $wht;
                outline: 0 !important;
                text-decoration: none;
            }
        }
        p {
            font-size: 15px;
            @media (max-width: 990px) {
                font-size: 13px;
            }
        }
    }
    
    input#username,
    input#password {
        width: 100%;
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    
    select {
        -moz-appearance: none;
        text-indent: 0.01px;
        text-overflow: '';
    }
    
    a,
    a:hover,
    a:focus,
    a:active {
        outline: 0 !important;
    }
    
    .logo {
        max-width: 250px;
        padding: 0.5em;
    }
    
    @media(max-width: 767px) {
        .logo {
            max-width: 175px;
        }
        .navbar-header a {
            float: left;
        }
    }
    
    @media (max-width: 600px) {
        .logo {
            max-width: 150px;
        }
        .navbar-toggle {
            margin-top: 12px;
        }
    }
    
    .top-pad {
        padding: 3em;
        background: white;
        @media (max-width: 990px) {
            padding: 1em;
        }
    }
    
    .flex {
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 1024px) {
            display: block;
            /* may need to be inline-block */
        }
    }
    
    .terms,
    .privacy {
        font-size: 1rem;
        & ol {
            li {
                font-size: 1rem;
            }
            & ol li {
                font-size: 0.85rem;
            }
        }
    }
    /* ========= BUTTONS ============ */
    
    .btn-download,
    .btn-download:focus,
    .btn-download:active,
    .btn-download:visited {
        background-color: $primary;
        border-color: $wht;
        color: $wht;
        padding: 10px 20px;
        font-size: 25px;
        border-radius: 5px;
        transition: all 1s;
        -webkit-transition: all 1s;
        -moz-transition: all 1s;
        -o-transition: all 1s;
    }
    
    .btn-download:hover {
        background-color: $secondary;
        color: $wht;
    }
    
    .btn-download {
        margin-bottom: 15px !important;
        display: block;
        max-width: 175px;
        margin-top: 15px;
    }
    
    .btn-download-2,
    .btn-download-2:focus,
    .btn-download-2:active,
    .btn-download-2:visited {
        background-color: $primary;
        border-color: $wht;
        color: $wht;
        padding: 10px 20px;
        font-size: 25px;
        border-radius: 5px;
        transition: all 1s;
        -webkit-transition: all 1s;
        -moz-transition: all 1s;
        -o-transition: all 1s;
    }
    
    .btn-download-2:hover {
        background-color: $secondary;
        color: $wht;
    }
    
    .btn-download-2 {
        margin-bottom: 15px !important;
        display: block;
        margin: 0px auto;
        max-width: 175px;
        margin-top: 15px;
    }
    /* ========= HEADER ========= */
    
    .header {
        background: url('../img/banner.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        text-align: center;
    }
    
    .header-overlay {
        background: rgba(31, 31, 31, .5);
        padding: 50px 0px;
        @media (max-width: 767px) {
            background: rgba(31, 31, 31, 5);
        }
    }
    
    .header h1 {
        margin-top: 50px;
        color: $wht;
        text-shadow: 2px 2px $primary;
    }
    
    .header p {
        color: $wht;
    }
    
    .header img {
        width: 100%;
        display: block;
        margin: 0px auto;
        max-width: 800px;
    }
    /* ========= SECTION ONE ========= */
    
    .section-one {
        padding: 50px 0px;
        text-align: center;
    }
    
    .section-one span {
        color: $primary !important;
    }
    
    .section-one h4 {
        font-size: 20px;
        font-style: normal;
        text-transform: uppercase;
        margin-bottom: 10px;
        font-weight: 500;
        line-height: 26px;
        color: #000;
    }
    
    .timeline-badge {
        color: #fff;
        position: absolute;
        top: 16px;
        left: 50%;
        -ms-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        border-radius: 50%;
        border: 2px solid #ffffff;
    }
    /* ========= SECTION TWO ========= */
    
    .section-two {
        background: url('../img/banner.jpg');
        background-repeat: no-repeat;
        background-position: left;
        background-size: cover;
        text-align: center;
    }
    
    .section-two-overlay {
        background: rgba(31, 31, 31, .5);
        padding: 100px 0px;
    }
    
    .section-two h2 {
        color: $wht;
    }
    
    .section-two p {
        color: $wht;
    }
    /* ========= SECTION THREE ========= */
    
    .section-three {
        padding: 50px 0px;
        text-align: center;
    }
    
    .section-three img {
        width: 100%;
        display: block;
        margin: 0px auto;
        max-width: 600px;
        border: 5px solid $primary;
        @media (max-width: 1024px) {
            margin-bottom: 25px;
        }
    }
    
    .section-three span {
        color: $primary !important;
    }
    
    .section-three p {
        margin-bottom: 50px;
    }
    /* ========= SECTION FOUR ========= */
    
    .section-four {
        background: url('../img/banner.jpg');
        background-repeat: no-repeat;
        background-position: left;
        background-size: cover;
        text-align: center;
    }
    
    .section-four-overlay {
        background: rgba(31, 31, 31, .5);
        padding: 100px 0px;
    }
    
    .section-four h2 {
        color: $wht;
    }
    
    .section-four p {
        color: $wht;
    }
    /*----------------------------------------------------------------------
  Divider
-----------------------------------------------------------------------*/
    
    .divider,
    .divider-2,
    .divider-3 {
        position: relative;
        display: inline-block;
        padding-bottom: 20px;
    }
    
    .divider,
    .divider-2 {
        padding-bottom: 35px;
    }
    
    .divider:before,
    .divider-2:before,
    .divider-3:before {
        content: "";
        position: absolute;
        bottom: 0;
        display: inline-block;
        left: 50%;
        margin-left: -118px;
    }
    
    .divider:before {
        background: url('../../img/divider-skin-default.png') no-repeat 0 0;
        width: 236px;
        height: 18px;
    }
    
    .divider-2:before {
        background: url('../../img/divider-skin-default.png') no-repeat 0 0;
        width: 236px;
        height: 25px;
    }
    
    .divider-3:before {
        background: url('../../img/divider-skin-default.png') no-repeat 0 0;
        margin-left: -48px;
        width: 97px;
        height: 3px;
    }
    
    .text-left .divider:before,
    .text-left .divider-2:before,
    .text-left .divider-3:before {
        left: 0;
        margin-left: 0;
    }
    
    .text-right .divider:before,
    .text-right .divider-2:before,
    .text-right .divider-3:before {
        left: inherit;
        margin-left: 0;
        right: 0;
    }
    
    .timeline>li>.timeline-panel {
        float: right;
    }
    
    .timeline-body:before {
        left: -60px;
        right: auto;
    }
    
    .timeline>li.timeline-inverted>.timeline-panel {
        float: left;
    }
    
    .timeline-inverted .timeline-body:before {
        right: -60px;
    }
    
    .timeline-section:before {
        position: absolute;
        bottom: -100px;
        left: 200x;
        z-index: 9;
        width: 373px;
        height: 1000px;
        content: "";
        background: url('../../img/timeline-img.png') no-repeat 0 0;
        background-size: contain;
    }
    
    .timeline-section:before {
        right: 20px;
        left: 0;
    }